import React from 'react'
import './navbar.css'

function navbar() {
    return(
        <nav className = 'bg-white-500 p-4'>
            <div className= "flex items-center justify-between w-screen">
                <a href='/'>
                    <img className = "logo" src={"/file.jpg"}/> 
                </a>
                <ul className ="md:flex space-x-4">
                    <li><a href="/team" className ="text-black">OUR TEAM</a></li>
                    <li><a href="/about" className ="text-black">ABOUT US</a></li>
                </ul>
                <button className="newsletter text-white font-bold py-2 px-4 rounded">
                    <a href='/preorder'>
                        Preorder Now
                    </a>
                </button>
            </div>
        </nav>

    )
}
export default navbar;
