import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const members = [
  { img: '/AlexMorenoHeadshot_BW.jpg', name: 'Alex Moreno', title: 'CEO', linkedin:"https://www.linkedin.com/in/alex-moreno/" },
  { img: '/LanceGlasser BW.jpg', name: 'Lance Glassner', title: 'Commercialization Advisor', linkedin:"https://www.linkedin.com/in/lance-glasser-b23aa72/" },
  { img: '/KrisPisterHeadshot copy.jpeg', name: 'Kristofer Pister', title: 'Technical Advisor', linkedin:"https://www.linkedin.com/in/kristofer-s-j-pister-513a54/"},
  { img: '/ChuckAlgerHeadshot_BW.jpeg', name: 'Chuck Alger', title: 'VP Manufacturing & Supply Chain', linkedin:"https://www.linkedin.com/in/chuckalger/"},
  { img: '/JoanneHeadshotBW.jpeg', name: 'Joanne Tran', title: 'Software Engineer Intern', linkedin:"https://www.linkedin.com/in/tranjoanne/"},
  { img: '/MillerLiu_BW.jpg', name: 'Miller Liu', title: 'Software Engineer Intern', linkedin:"https://www.linkedin.com/in/feihong-miller-liu/" },
  { img: '/ChiaraHeadshot_BW.jpeg', name: 'Chiara Anneliese', title: 'Marketing Intern', linkedin:"https://www.linkedin.com/in/chiara-anneliese/" },
  { img: '/AndrewWang_Headshot_BW.jpg', name: 'Andrew Wang', title: 'IOS Development Intern', linkedin:"https://www.linkedin.com/in/awang502/"},
  { img: '/Vivek HeadshotBW.jpeg', name: 'Vivek Putta', title: 'IOS Development Intern', linkedin:"https://www.linkedin.com/in/vivek-putta-8305a8255/"}
];

function TeamCard({ img, name, title, linkedin }) {
  return (
    <div className="bg-white rounded-lg shadow p-6 text-center">
      <img src={img} alt={name} className="w-24 h-24 rounded-full mx-auto mb-4 object-cover" />
      <div className="text-lg font-medium text-gray-900">{name}</div>
      <div className="text-sm font-semibold text-gray-500">{title}</div>
      <div className="flex justify-center gap-2 mt-4">
        {linkedin && (
          <a href={linkedin} target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:text-gray-700">
            <i className="fa-brands fa-linkedin" />
          </a>
        )}
      </div>
    </div>
  );
}

function Team() {
  return (
    <section className="min-h-screen py-8 px-8 lg:py-28 bg-gray-50">
      <div className="container mx-auto text-center">
        <h6 className="text-lg text-gray-600">Meet the Team</h6>
        <h1 className="text-2xl lg:text-4xl text-gray-800 my-2">Behind eNano: Our Dedicated Team</h1>
        <p className="text-gray-500 mx-auto max-w-4xl mb-6">
          From visionary leadership to creative/technical talent, each team member plays a pivotal role in
          delivering innovative solutions.
        </p>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 mt-300">
          {members.map((member, index) => (
            <TeamCard key={index} {...member} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;
