import './home.css'
import { InstagramEmbed } from 'react-social-media-embed';

function Home() {
    return (
        <div className="HomePage">
            <div className="section">
                <div id="titleContainer">
                    <h1>SMALLER SMARTER BETTER</h1>
                    <p>See for yourself.</p>
                </div>
                <img src={"/8.png"}/>
                {/* <div id="imageSelector">
                    <div className='option active'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                    <div className='option'></div>
                </div> */}
            </div>
            <div className="section">
                <div className="grid-content">
                    <div className="card">
                        <h1>Why Nanotags?</h1>
                        <p>Discover how eNano is redefining sustainability in IoT with our standout features.</p>
                    </div>
                    <div className="card">
                        <div className="upperCard">
                            <p className="sectionName">nanoTags: Small is the New Powerful</p>
                            <p className="sectionDescription">Introducing nanoTags, the world’s smallest smart tags—14 times smaller, infinitely smarter. Seamlessly track your most essential items, from your passport and jewelry to your headphones and tools. With nanoTags, you’re always connected to what matters most, in a design so small, you’ll barely notice it’s there—until you need it.</p>
                            <img src={"/IMG_5480.jpg"}/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="upperCard">
                            <p className="sectionName">Power That Never Quits</p>
                            <p className="sectionDescription">Say farewell to batteries and charging cables. nanoTags are completely battery-free, powered by a revolutionary ultra-low-power Bluetooth chip and solar energy harvesting. With zero maintenance required, these tags are always ready, always on—no charging needed. Because true innovation means never having to think about power again.</p>
                            <img src={"/no-battery-icon-or-logo-forbidden-using-battery-is-not-allowed-illustration-free-vector.png"}/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="lowerCard">
                            <p className="sectionName">Instant Alerts, Timely Action</p>
                            <p className="sectionDescription">Traditional smart tags often fall short, alerting you minutes after you’ve already left something behind. But with nanoTags, you’re always in the know. Receive real-time notifications the moment you step away from your valuables, so you can take action instantly. Whether it’s your keys at home or your wallet at a restaurant, nanoTags ensure you’re never a step behind—because in those crucial moments, every second matters.</p>
                            <img src={"/notif.png"}/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="lowerCard">
                            <p className="sectionName">Seamless Setup, Effortless Use</p>
                            <p className="sectionDescription">With nanoTags, simplicity meets reliability. We’ve chosen trusted 3M adhesive strips to ensure your nanoTag stays securely in place. And when life changes, your nanoTags adapt with extra 3M strips included for easy reapplication. Just peel, stick, and forget—it’s that effortless</p>
                            <img src={"/attachablepass.jpg"}/>
                        </div>
                    </div>
                    <div className="card">
                        <div className="lowerCard">
                            <p className="sectionName">Leave the Details to AI</p>
                            <p className="sectionDescription">Life moves fast, and it’s easy to overlook the essentials. Enter eNano’s AI Butler—a smart assistant that anticipates your needs and sends gentle reminders just when you need them. Whether it’s your passport before a big trip or your keys before you head out the door, nanoTags ensure you’re always prepared. Simplify your day and let nanoTags handle the details, so you can stay focused on what truly matters.</p>
                            <img src={"/Screenshot 2024-07-02 at 12.34.25 PM.png"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="gallery-content">
                    <h1>SPECIFICATIONS</h1>
                    <div className="gallery">
                        <div className="gallery-card">
                            <img src={"/360_F_738968048_Ns8pKOZbKrSfWbX9h8dO4Y1LHSbdtuVw.jpg"}></img>
                            <div className="gallery-card-text">
                                <h1>Nanotags</h1>
                                <p>6x20.8x3.5mm</p>
                                <p>500mg Weight</p>
                                <p>IP68 waterproof rating</p>
                                <p>Power reservoir for 7 days</p>
                                <p>energy harvesting solar</p>
                                <p>5 hours charge time from 0-100 in bright sunlight </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="who-we-are">
                    <div className="title">
                        <p>Who we are</p>
                        <h1>ENano: delivering technology-driven solutions</h1>
                    </div>
                    <div className="description-container">
                        <img src={"/Screenshot 2024-07-26 at 3.26.30 PM.png"}></img>
                        <div className="description">
                            <h1>Our story</h1>
                            <p>At eNano Technologies, we’re redefining how you connect with the items that matter most. Our innovative nano technology, like the eNano Tag, offers simple yet powerful solutions for tracking and protecting your valuables.</p>
                            <br></br>
                            <p>We believe in combining cutting-edge technology with user-friendly design, making our products not just functional, but a seamless part of your everyday life.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="signup-form">
                    <div className="form">
                        <h1>Sign up for our waitlist</h1>
                        <p>Your Vision, Our Mission:<br/>Let's Shape Success Together.</p>
                        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdlLGL6yVVLXlu6YlX5hLE0G1rHpMKhy3gd095KlGonzK0cGw/viewform?embedded=true" width="640" height="1489" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                    </div>
                </div>
                <div className="bottom">
                    <div className="bottom-content">
                        <h1>Contact us</h1>
                        <div>
                            <a href='https://www.instagram.com/enanotechnologies?igsh=MzRlODBiNWFlZA=='><i class="fa-brands fa-instagram"></i></a>
                            <a href=''><i class="fa-brands fa-facebook-f"></i></a>
                            <a href=''><i class="fa-brands fa-x-twitter"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
