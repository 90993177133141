import './App.css';
import Home from './pages/home.jsx';
import Navbar from './components/navbar.tsx'
import Team from './pages/team';
import Preorder from './pages/preorder';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/about';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/preorder" element={<Preorder />}></Route>
        <Route path="/about" element={<About />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
