function About() {
    return (
      <div className="min-h-screen py-8 px-8 lg:py-28 bg-gray-50 font-sans">
        <h1 className="text-2xl lg:text-4xl text-gray-800 px-10">Our Company Values</h1>
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center -mx-4">
            <div className="w-full px-12 lg:w-1/2">
              <div
                className="relative mx-auto mb-12 max-w-[500px] text-center lg:m-0"
              >
                <img
                  src="/Screenshot 2024-07-26 at 3.26.30 PM.png"
                  alt="about image"
                  layout="fill"
                  className="py-14"
                />
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2">
              <div className="max-w-[470px]">
                <div className="mb-9">
                  <h3 className="mb-4 text-xl font-bold text-black sm:text-2xl lg:text-xl xl:text-2xl">
                    Innovation
                  </h3>
                  <p className="text-base leading-relaxed text-gray-700 sm:text-lg sm:leading-relaxed">
                    Always pushing boundaries to create the next big thing.
                  </p>
                </div>
                <div className="mb-9">
                  <h3 className="mb-4 text-xl font-bold text-black sm:text-2xl lg:text-xl xl:text-2xl">
                    Quality
                  </h3>
                  <p className="text-base leading-relaxed text-gray-700 sm:text-lg sm:leading-relaxed">
                    Meticulous attention to detail in everything we do.
                  </p>
                </div>
                <div className="mb-9">
                  <h3 className="mb-4 text-xl font-bold text-black sm:text-2xl lg:text-xl xl:text-2xl">
                    User-Focused
                  </h3>
                  <p className="text-base leading-relaxed text-gray-700 sm:text-lg sm:leading-relaxed">
                    Designed with you in mind, for simplicity and ease.
                  </p>
                </div>
                <div className="mb-9">
                  <h3 className="mb-4 text-xl font-bold text-black sm:text-2xl lg:text-xl xl:text-2xl">
                    Sustainability
                  </h3>
                  <p className="text-base leading-relaxed text-gray-700 sm:text-lg sm:leading-relaxed">
                    Committed to minimizing environmental impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  export default About;
  